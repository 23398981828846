import alarm from '../images/operation-img/alarm.png';
import wckh from '../images/operation-img/wckh.png';
import bas from '../images/operation-img/bas.png';
import fss from '../images/operation-img/fss.png';
import Project1 from '../images/operation-img/project-1.webp';
import Project3 from '../images/operation-img/project-3.webp';
import Project5 from '../images/operation-img/project-5.webp';
import Project6 from '../images/operation-img/project-6.webp';
import oilspill from '../images/operation-img/oilspill.jpg';
import fireproof from '../images/operation-img/fireproof.jpg';
import ppe from '../images/operation-img/ppe.jpg';
import offshore from '../images/operation-img/offshore.jpg';

const OpCards = [
    {
        id: 1,
        title: 'Fire Alarm/Detection Systems',
        subtitle:
            'Chrisason ensures the fire safety through the installation and maintenance of fire alarm systems. Special care is taken by our team in selecting one that is well suited to your company needs.',
        img: alarm,
    },
    {
        id: 2,
        title: 'Wet Chemical Kitchen Hood Fire Suppression System',
        subtitle:
            'We specialise in the design, supply, install, service, maintain, recharge of wet chemical hood fire suppression systems to enhance fire protection when spills or accidents occur in the kitchen.',
        img: wckh,
    },
    {
        id: 3,
        title: 'Breathing Air Systems',
        subtitle:
            'We specialise in installing Breathing Air Systems which are vital in fire safety and are essential for those who live in high-rise buildings. They help firefighters reach areas where there are higher concentrations of smokes.',
        img: bas,
    },
    {
        id: 4,
        title: 'FM200, CO2, Inergen and Argon Suppression Systems',
        subtitle:
            'We specialise in installing and maintaining various fire suppression systems including FM200, Inergen, Argon and CO2. They are all essential to the fire protection field and we ensure their usefulness.',
        img: fss,
    },
    {
        id: 5,
        img: Project5,
        title: 'Tank Protection System',
        subtitle:
            'Fire in a storage tank, or in the surrounding bund, is a challenge to both fire fighters and tank operators. The value of the contents makes the provision...',
        link: '/',
    },
    {
        id: 6,
        img: Project6,
        title: 'Factories + Industrial Fire Protection',
        subtitle:
            'Factories and warehouses around the country and the world are responsible for the manufacture and storage of countless items that we use on a daily basis...',
        link: '/',
    },
    {
        id: 7,
        img: Project1,
        title: 'Fire Safety & QHSE Training',
        subtitle:
            'Our fire safety training courses offer comprehensive, accredited training to individuals, managers and organisations. We offer fire safety courses in....',
        link: '/',
    },

    {
        id: 8,
        img: Project3,
        title: 'Maintenance Service Levels',
        subtitle:
            'Our engineers are highly trained to assemble + install fire alarm + fire suppression systems. Alongside our installation, we will offer inspection, testing + maintenance...',
        link: '/',
    },

    {
        id: 9,
        img: oilspill,
        title: 'Oil Spillage Clean up',
        subtitle:
            'Our expert teams are extensively trained in swift and efficient oil spillage cleanup operations. Alongside our cleanup expertise, we offer thorough inspection, advanced techniques...',
        link: '/',
    },

    {
        id: 10,
        img: fireproof,
        title: 'Fireproofing',
        subtitle:
            'We specialize in top-tier fireproofing solutions. Our seasoned professionals are adept at applying state-of-the-art fire-resistant coatings and materials....',
        link: '/',
    },

    {
        id: 11,
        img: ppe,
        title: 'Specialised Fire / PPE Procurement (Arc Flash, Anti-Static & Fire Retardant, Skid-mounted Fire Pump)',
        subtitle:
            'We excel in the procurement of specialized fire and personal protective equipment (PPE). From arc flash gear and anti-static apparel to fire retardant solutions and skid-mounted fire pumps....',
        link: '/',
    },

    {
        id: 12,
        img: offshore,
        title: 'Offshore Fire Protection',
        subtitle:
            'We are your trusted partner in offshore fire protection solutions. With a deep understanding of the unique challenges posed by offshore environments, we offer specialized fire prevention, detection, and response systems.',
        link: '/',
    },
];

export { OpCards };
